import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import config from './common/config'
import * as common from './common'
Vue.use(ElementUI);
Vue.use(Antd);
Vue.config.productionTip = false
// antdvLibs
import antdvLibs from 'antdv-libs'
import 'antdv-libs/lib/AntdvLibs.css'
Vue.prototype.$common = common
Vue.use(antdvLibs, { settings: config })
import * as echarts from 'echarts';
 
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
