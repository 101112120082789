<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import cookieUtil from "@/common/cookie-util";

export default {
  // ...省略其他选项...
  data() {
    return {
      type: "", // 设备类型：'手机', '平板', '电脑'
      windowWidth: 0, // 窗口宽度
      windowHeight: 0, // 窗口高度
      cols: 0,
    };
  },
  computed: {

    computedCols() {
      if (this.windowWidth > 1500) {
        return 1;
      } else if (this.windowWidth > 750) {
        return 2;
      } else {
        return 3;
      }
    },
    // 确定路由路径
    routePath() {
      const basePaths = { 1: "/", 2: "/PadIndex", 3: "/McIndex" };
      const loginPaths = { 1: "/login", 2: "/Padlogin", 3: "/Mclogin" };
      const token = cookieUtil.getToken();
      const cols = this.computedCols;
      return token ? basePaths[cols] : loginPaths[cols];
    },
  },
  methods: {
    getDeviceInfo() {
      // 简化设备类型检测逻辑
      const ua = navigator.userAgent;
      this.type = /iPad/.test(ua)
        ? "平板"
        : /iPhone/.test(ua) && !/iPad/.test(ua)
        ? "手机"
        : /Android/.test(ua)
        ? /Mobile/.test(ua)
          ? "手机"
          : "平板"
        : "电脑";
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.getDeviceInfo();
      this.cols = this.computedCols; // 使用计算属性设置列数
      this.navigateBasedOnColsAndToken(); // 根据列数和token进行路由跳转
    },
    navigateBasedOnColsAndToken() {
      const currentPath = this.$router.currentRoute.path;
      const targetPath = this.routePath;

      if (currentPath !== targetPath) {
        this.$router.replace(targetPath).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            // 处理或报告非重复导航的错误
            throw err;
          }
          // 对于重复导航错误，你可以选择在这里进行日志记录或不做任何操作
        });
      }
    },
  },
  mounted() {
    this.updateWindowSize(); // 初始化时设置窗口大小和设备信息
    // window.addEventListener("resize", this.updateWindowSize); // 监听窗口大小变化并更新相关信息和路由（如果需要）
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.updateWindowSize); // 组件销毁前移除事件监听器，防止内存泄漏
  },
};
</script>
<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
}
@media (min-width: 1500px) and (max-width: 1650px) {
  html,
  body,
  #app {
    /* 在这个断点处调整字体大小 */
    font-size: 9.8px !important;
  }
}
@media (min-width: 1651px) and (max-width: 1899px) {
  html,
  body,
  #app {
    /* 在这个断点处调整字体大小 */
    font-size: 12.8px !important;
  }
}
@media (min-width: 1900px) and (max-width: 2000px) {
  html,
  body,
  #app {
    /* 在这个断点处调整字体大小 */
    font-size: 13.3px !important;
  }
}
@media (min-width: 2001px) and (max-width: 3000px) {
  html,
  body,
  #app {
    /* 在这个断点处调整字体大小 */
    font-size: 18.6px !important;
  }
}
@media (min-width: 2561px) {
  html,
  body,
  #app {
    /* 在这个断点处调整字体大小 */
    font-size: 20.5px;
  }
}
</style>
