import Vue from 'vue'
import VueRouter from 'vue-router'
// import pageJson from '../views/pageJson.vue'

Vue.use(VueRouter)

const routes = [
  // 三端登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/Padlogin',
    name: 'Padlogin',
    component: () => import('../views/login/Padlogin.vue')
  },
  {
    path: '/Mclogin',
    name: 'Mclogin',
    component: () => import('../views/login/Mclogin.vue')
  },
  // 3端页面
  //pc端
  {
    // path: '/PcIndex',
    path: '/',
    name: 'PcIndex',
    component: () => import( '../views/home/PcIndex.vue')
  },
  //视频端
  {
    path: '/VideoIndex',
    name: 'VideoIndex',
    component: () => import( '../views/video/VideoIndex.vue')
  },
  //pad端
  {
    path: '/PadIndex',
    name: 'PadIndex',
    component: () => import( '../views/home/PadIndex.vue')
  },
  //手机端
  {
    path: '/McIndex',
    name: 'McIndex',
    component: () => import( '../views/home/McIndex.vue')
  }
]

const router = new VueRouter({
  routes
})



export default router
